<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="etat_activite_form"
      name="etat_activite_form"
      class="adherent_form"
      :form="form"
    >
      <a-row type="flex" justify="center">
        <a-col :span="20">
          <a-form-item v-bind="formItemLayout" label="Secteur d'activité : ">
            <a-select
              placeholder="Secteur activite"
              :allowClear="true"
              style="width: 76%"
              v-decorator="[
                'secteur_activite',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Secteur activite est obligatoire!',
                    },
                    { validator: checkEmptyValue },
                  ],
                },
              ]"
            >
              <a-select-option :value="0">Tous</a-select-option>
              <a-select-option v-for="etat in secteurs" :key="etat.id">
                {{ etat.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="Etat professionnel">
            <a-select
              placeholder="Etat professionel"
              :allowClear="true"
              style="width: 76%"
              v-decorator="[
                'statut_professionnel',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Statu professionnel est obligatoire!',
                    },
                    { validator: checkEmptyValue },
                  ],
                },
              ]"
            >
              <a-select-option value="">Tous</a-select-option>
              <a-select-option
                v-for="etat in statuts_professionels"
                :key="etat.id"
              >
                {{ etat.nom_fr }}
              </a-select-option>
            </a-select>
          </a-form-item>
                   <a-form-item v-bind="formItemLayout" label="Date">
            <a-date-picker
              style="width: 76%"
              :format="dateFormat"
              :disabledDate="disabledDates"
              :disabled="disableDateChangement"
              v-decorator="[
                'date_changement',
                {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Date est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
      <a-form-item v-bind="{ xs: { span: 24 }, sm: { span: 24 } }">
        <a-checkbox
        @change="handleStagiaireChange"
          v-decorator="[
            'is_stagiaire',
            {
              valuePropName: 'checked',
              initialValue: get(etatActivite, 'is_stagiaire', false),
            },
          ]"
        >
        Stagiaire
        </a-checkbox>
      </a-form-item>
     <a-form-item v-bind="formItemLayout" label="Tuteur:"  v-if="isStagiaire">
        <a-input
        style="width:76%"
          v-decorator="[
            'medecin_responsable',
            {
              rules: [
                {
                  required: isStagiaire,
                  message: 'Médecin Responsable est obligatoire!',
                },
              ],
            },
          ]"
        />
      </a-form-item>

 

                  <a-form-item  v-bind="formItemLayout" label="Date fin :"  v-if="isStagiaire">
                    <a-date-picker
              style="width:76%"
              :format="dateFormat"
              :disabledDate="disabledDates"
              :disabled="disableDateChangement"
              v-decorator="[
                'fin_stage',
                {
                  rules: [
                    {
                      type: 'object',
                      required: isStagiaire,
                      message: 'Date fin est obligatoire!',
                    }
                     
                  ],
                },
              ]"
            />
      </a-form-item>

          <a-form-item v-bind="formItemLayout" label="Motif">
            <textarea
              style="width: 76%"
              autosize
              name="motif"
              v-decorator="[
                'motif',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Motif est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        :loading="processing"
        @click="handleOk"
        key="submit"
        type="primary"
        >Valider
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import { mapState } from "vuex";
const SECTEUR_STAGIAIRE = 4;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export default {
  name: "EtatActiviteEditor",
  created() {
    this.$store.cache.dispatch("fetchSecteurs");
  },
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout,
      isStagiaire: false,
      statut_professionnel: "",
      secteur_activite: "",
      form: this.$form.createForm(this),
    };
  },
  props: {
    visible: { type: Boolean, default: () => false },
    processing: { type: Boolean, default: () => false },
    disableDateChangement: { type: Boolean, default: () => false },
    etatActivite: { type: Object },
    dateInstallation: { type: String },
  },
  computed: {
    ...mapState({
      secteurs: (state) => state.secteurs.secteurs,
      statuts_professionels: (state) => state.statuts.statuts, 
    }),
    operationTitle() {
      return this.etatActivite ? `Modifier` : `Nouvelle état`;
    },
    isSecteurActiviteStagiaireStagiaire() {
      return (
        this.etatActivite &&
        this.etatActivite.secteur_activite === SECTEUR_STAGIAIRE
      );
    },
    title() {
      return ` ${this.operationTitle} (date d'installation ${this.date(
        this.dateInstallation
      )})`;
    },
  },
  methods: {
    disabledDates(current) {
      return (
        this.disabledFutureDates(current) ||
        this.disabledDatesBeforeDateInstallation(current)
      );
    },
    disabledDatesBeforeDateInstallation(current) {
      return current && current < this.momentDate(this.dateInstallation);
    },
    close() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleOk() {
      this.$emit("create");
    },
    fillTheForm() {
      if (this.etatActivite) {
        this.isStagiaire = this.etatActivite.is_stagiaire;
        this.$nextTick(() => {
          this.form.setFieldsValue(this.etatActivite);
          this.form.setFieldsValue({
            date_changement: this.momentDate(this.etatActivite.date_changement),
          });
           this.form.setFieldsValue({
            fin_stage: this.momentDate(this.etatActivite.fin_stage),
          });
          
 
        });
      }
    },
    handleOrganismeChange(value) {
      this.selected_organisme_id = value;
    },
    handleStagiaireChange() {
      // eslint-disable-next-line no-undef
     this.isStagiaire = is_stagiaire.checked;
     if (this.etatActivite) {
       this.medecin_responsable=this.etatActivite.medecin_responsable;
     }


    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    },
  },
};
</script>
