<template>
  <a-spin :spinning="processing">
    <a-table class="over_flow" :columns="columns" :dataSource="etatActivites" bordered>
      <template slot="row_index" slot-scope="text, record, index">
        <p>{{ index + 1 }}</p>
      </template>
      <template slot="name" slot-scope="text">
        <a href="javascript:;">{{ text }}</a>
      </template>
      <template slot="statut" slot-scope="text, record, index">
        <StatuProfessionalIndicator
          :statut-professionnel="record.statut_professionnel"
        />
      </template>
      <template slot="secteur" slot-scope="text, record, index">
        {{get(record, 'secteur.nom_fr')}}
      </template>
      <template slot="date" slot-scope="text, record, index">
        {{ record.date_changement | date }}
      </template>
      <template slot="upload" slot-scope="text, record, index">
        <!-- _______________________UPLOAD -->

         <Uploader   name="file" :action="uploadUrl(record.id)" :record="record" 
                                      :bearer="getAuthorizationToken()"
                                      @upload_success="$emit('refresh_data', $event)"
                                      @delete_file="$emit('delete_file', $event)"/>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <!-- _________________________VISUALIZE -->
        <a-tooltip placement="top" v-if="record.file_name">
          <template slot="title">
            <span>Visualiser</span>
          </template>
          <a-icon
            type="search"
            @click="previewDocument(record.file_url)"
            class="action_icons"
          />
        </a-tooltip>
        <!-- _________________________DELETE -->
        <a-popconfirm
          title="Etes-vous sûr de vouloir supprimer cette ligne ?"
          @confirm="() => deleteEtatActvite(record.id)"
        >
          <a-tooltip placement="top">
            <template slot="title">
              <span>Suppression</span>
            </template>
            <a-icon
              type="delete"
              class="action_icons"
              theme="twoTone"
              twoToneColor="#eb2f96"
            />
          </a-tooltip>
          <!--  EDIT -------->
        </a-popconfirm>
        <a-tooltip placement="top">
          <template slot="title">
            <span>Edition</span>
          </template>
          <a-icon
            type="edit"
            v-on:click="editEtatActivite(index, record)"
            class="action_icons"
            style="margin-right:10px;"
            theme="twoTone"
            twoToneColor="#52c41a"
          />
        </a-tooltip>
      </template>
      <template slot="current" slot-scope="text, record, index">
        <a-icon
          v-if="index === 0"
          type="check-circle"
          theme="twoTone"
          twoToneColor="#52c41a"
        />
      </template>
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <a-row type="flex">
          <a-col :span="4">
            <b>Motif :</b>
          </a-col>
          <a-col :span="16">
            <textarea
              autosize
              :value="record.motif"
              disabled
              style="border: none;
                 background-color: initial;"
            />
          </a-col>
        </a-row>
      </div>
    </a-table>
  </a-spin>
</template>
<script>
const columns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Statut Professionnel",
    scopedSlots: { customRender: "statut" },
  },
  {
    title: "Secteur Activite",
    scopedSlots: { customRender: "secteur" },
  },
  {
    title: "Date",
    scopedSlots: { customRender: "date" },
  },
  {
    title: "Opération",
    scopedSlots: { customRender: "operation" },
  },
  {
    title: "Importation",
    scopedSlots: { customRender: "upload" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "current" },
  },
];
import StatuProfessionalIndicator from "@/components/common/StatuProfessionalIndicator";
import Uploader from "@/components/common/Uploader";
import { mapState } from "vuex";
export default {
  components: {
    StatuProfessionalIndicator,
    Uploader
  },
  data() {
    return {
      columns,
      visible: false,
      newComponentKey: 0,
    };
  },
  props: { etatActivites: Array, processing: { type: Boolean, default: () => true } },
  computed: {
    ...mapState({
      base_url: (state) => state.auth.base_url,
    }),
  },
  methods: {
    getDemandeAttestationComponentKey() {
      this.newComponentKey = this.getRandomInt();
    },
    deleteEtatActvite(id) {
      this.$emit("delete", id);
    },
    editEtatActivite(index, etatActivite) {
      this.$emit("edit", { index: index, etatActivite: etatActivite });
    },
    uploadUrl(etatActivite) {
      return `${this.base_url}/etat-activite/upload-documents/${etatActivite}`;
    },
  },
};
</script>
<style scoped>
.table_head {
  margin-bottom: 5%;
}
.action_icons {
  margin: 4px;
  font-size: large;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
